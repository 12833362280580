
body {
	background-color: #fcfcfd;
}

.moba-input:focus {
	outline: 2px solid #00D3AA !important;
}
@font-face {
	font-family: "Aeonik-Air";
	/* the file of the font is in public/fonts/Gilroy-Semibold.woff2" */
	src: url("fonts/Aeonik-Air.otf") format("otf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Aeonik-Regular";
	/* the file of the font is in public/fonts/Gilroy-Semibold.woff2" */
	src: url("fonts/Aeonik-Regular.otf") format("otf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Aeonik-Bold";
	/* the file of the font is in public/fonts/Gilroy-Semibold.woff2" */
	src: url("fonts/Aeonik-Bold.otf") format("otf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Aeonik-Black";
	/* the file of the font is in public/fonts/Gilroy-Semibold.woff2" */
	src: url("fonts/Aeonik-Black.otf") format("otf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Aeonik-Light";
	/* the file of the font is in public/fonts/Gilroy-Semibold.woff2" */
	src: url("fonts/Aeonik-Light.otf") format("otf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Aeonik-Medium";
	/* the file of the font is in public/fonts/Gilroy-Semibold.woff2" */
	src: url("fonts/Aeonik-Medium.otf") format("otf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Aeonik-Thin";
	/* the file of the font is in public/fonts/Gilroy-Semibold.woff2" */
	src: url("fonts/Aeonik-Thin.otf") format("otf");
	font-weight: 600;
	font-style: normal;
}
.rti--container {
	--rti-bg: "#fff";
	--rti-border: "#ccc";
	--tw-ring-color: "#027A48";
	--rti-main: "#027A48";
	--rti-radius: "0.375rem";
	--rti-s: "0.5rem"; /* spacing */
	--rti-tag: "#ecfdf3";
	--rti-tag-remove: "#027A48";
}
.go2286398182 {
	background: #ecfdf3 !important;
}
.go2286398182 > span {
	color: #027a48 !important;
}
.chip {
	background: #ecfdf3 !important;
	color: #027a48 !important;
}

.modal {
	padding-right: 0!important;
}
.modal-footer {
	overflow: hidden;
	align-items: normal;
	background: white;
    margin-bottom: 20px;
}

.side-modal .modal-dialog {
	height: 100%;
	margin: 0px;
}

.side-modal .modal-content {
	height: 100%;
}

.modal-body {
	background: white;
	font-style: normal;
	display: flex;
	height: 100%;
	color: #101828;
	font-family: Aeonik-Regular, sans-serif;
}
.modal {
	right: 0 !important;
	left: auto !important;
}
.h1012 {
	height: 87%;
}
button:focus {
	outline: none !important;
}

/* Style instructions for react-dropdown components */
.Dropdown-control {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)!important;
	border-radius: 0.3rem!important;
	border: 1px solid #dee2e6 !important;
}
.Dropdown-control:hover {
	cursor: pointer;
}

.Dropdown-placeholder {
	padding-left: 12px;
}

/* Style instructions for react-multiselect components */
.search-wrapper {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)!important;
	border-radius: 0.3rem!important;
	border: 1px solid #dee2e6 !important;
}
.searchBox {
	padding-left:12px;
}

.rti--container {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)!important;
	border-radius: 0.3rem!important;
	border: 1px solid #dee2e6 !important;
}

.rti--input {
	width: 100% !important;
	padding-left: 12px;
}

.modal-footer {
	border-top: none !important;
}

.react-code-input input:focus {
	outline: 2px solid #00D3AA !important;
}

.react-code-input input::placeholder {
	color: rgb(211, 211, 211) !important;
}